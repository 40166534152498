import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { MetricGroupValue } from '@/openapi/meta/model';
import { MetricRequest, Nullable } from '@/common/utils/types';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { DataMetricResponse, MetricResponseItem } from '@/openapi/data/model';
import { getByUserIdAndTenantIdMetricGroupV7ControllerAxios } from '@/openapi/common/api/metric-group-v7-controller-api';
import { MetricFailError, checkValidMetric } from '@/database/utils/utils';
import { getMetricData } from '@/common/api/data';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

type MetricGroup = Nullable<MetricGroupValue>;

export interface State {
  metricGroup: MetricGroup[];
  dbMetricInfo: MetricResponseItem[][];
  previewDbMetricInfo: MetricResponseItem[][];
  errorStatusText: string;
}

const frameName = FRAME_NAMES.DB_METRIC.METRIC;
export const metric: Module<State, RootState> = {
  namespaced: true,
  state: {
    metricGroup: [],
    dbMetricInfo: [],
    previewDbMetricInfo: [],
    errorStatusText: '',
  },
  mutations: {
    initPreviewDbMetricInfo: (state: State) => {
      state.previewDbMetricInfo = [];
    },
    initDbMetricInfo: (state: State) => {
      state.dbMetricInfo = [];
    },
    setMetricGroup(state: State, metricGroup: MetricGroup[]) {
      const DEFAULT_GROUP: MetricGroup[] = [
        {
          metricGroupName: 'Logical + Physical IO',
          type: 'mysql',
          createdTime: null,
          metricNames: [
            'db_mysql_innodb_buffer_pool_reads',
            'db_mysql_innodb_buffer_pool_read_requests',
          ],
        },
      ];
      state.metricGroup = [...DEFAULT_GROUP, ...metricGroup];
    },
    setDbMetricInfo: (state: State, data: MetricResponseItem[][]) => {
      state.dbMetricInfo = data;
    },
    setPreviewDbMetricInfo: (state: State, data: MetricResponseItem[][]) => {
      state.previewDbMetricInfo = data;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    fetchMetricGroup: async ({ commit }) => {
      const { data } = await getByUserIdAndTenantIdMetricGroupV7ControllerAxios({} as any);
      commit('setMetricGroup', data.data);
    },
    fetchDbMetricInfo: async (
      { commit },
      {
        metricInfo,
        instanceIds,
      }: { metricInfo: { metricName: string; interval: string }[]; instanceIds: string[] },
    ) => {
      try {
        const deduplicationMetricInfo = metricInfo.filter(
          ({ metricName }, idx, src) =>
            src.findIndex((val) => val.metricName === metricName) === idx,
        );

        let convertedData;
        if (deduplicationMetricInfo.length) {
          // TODO: 수집주기(interval) 관련해서 확인 필요
          const oneMinuteInterval = deduplicationMetricInfo.filter(
            ({ interval }) => interval === '1m0s',
          );

          if (oneMinuteInterval.length) {
            const metricRequests = oneMinuteInterval.map<MetricRequest>(({ metricName }) => ({
              aggregationType: 'byTarget',
              period: 'p1h',
              interval: 'I1m',
              category: 'mysql',
              dataId: metricName,
              interpolateType: 'Null',
              summaryType: 'current',
              targetIds: instanceIds,
            }));

            const promises: PromiseAxiosResponse<DataMetricResponse>[] = [
              getMetricData({
                metricV7Requests: metricRequests,
                frameName: FRAME_NAMES.DB_METRIC.METRIC_I1M,
                isPolling: true,
              }),
            ];
            const others = deduplicationMetricInfo.filter(({ interval }) => interval !== '1m0s');
            if (others.length) {
              const othersMetricRequests = others.map<MetricRequest>(({ metricName }) => ({
                aggregationType: 'byTarget',
                period: 'p3m',
                interval: 'I5s',
                category: 'mysql',
                interpolateType: 'Linear',
                dataId: metricName,
                summaryType: 'current',
                targetIds: instanceIds,
              }));
              promises.push(
                getMetricData({
                  metricV7Requests: othersMetricRequests,
                  frameName: FRAME_NAMES.DB_METRIC.METRIC_I5S,
                  isPolling: true,
                }),
              );
            }

            const result = await Promise.allSettled(promises);
            result.forEach((value) => {
              if (value.status === 'fulfilled') {
                const { data } = value.value.data;
                if (convertedData?.length) {
                  data?.forEach(({ status, reason, metrics }, idx) => {
                    if (status === 'fail') {
                      throw new MetricFailError({ reason });
                    }
                    convertedData[idx] = metrics;
                  });
                } else {
                  convertedData = data;
                }
              }
            });

            commit('mysqlMultiViewEnv/deleteFramesByFailedApi', FRAME_NAMES.DB_METRIC.METRIC_I1M, {
              root: true,
            });
            commit('mysqlMultiViewEnv/deleteFramesByFailedApi', FRAME_NAMES.DB_METRIC.METRIC_I5S, {
              root: true,
            });
          } else {
            const metricRequests = deduplicationMetricInfo.map<MetricRequest>(({ metricName }) => ({
              aggregationType: 'byTarget',
              period: 'p3m',
              interval: 'I5s',
              category: 'mysql',
              interpolateType: 'Linear',
              dataId: metricName,
              summaryType: 'current',
              targetIds: instanceIds,
            }));

            const { data } = await getMetricData({
              metricV7Requests: metricRequests,
              frameName,
              isPolling: true,
            });

            checkValidMetric(data?.data ?? []);

            convertedData = data.data;
          }
        } else {
          convertedData = [];
        }

        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });

        return convertedData;
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
        return [];
      }
    },
  },
  getters: {
    getMetricGroup: (state: State) => state.metricGroup,
    getDbMetricInfo: (state: State): MetricResponseItem[][] => state.dbMetricInfo,
    getPreviewDbMetricInfo: (state: State): MetricResponseItem[][] => state.previewDbMetricInfo,
    getErrorStatusText: (state: State): string => state.errorStatusText,
    getMetricParams: (state, getters, rootState, rootGetters) => {
      const allMetricNames = rootGetters['dbMetric/getMetricNames'];
      const metricNames: string[] = rootGetters['mysqlMultiViewEnv/getDbMetricNames'];

      return metricNames.map((metricName) => ({
        metricName,
        interval:
          allMetricNames.find((target) => target.metricName === metricName)?.interval ?? '5s',
      }));
    },
  },
};
