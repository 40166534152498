import { useInternational } from '@/common/locale';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import { useRtmApi } from '@/common/utils/apiUtils';
import {
  AlertTreeRow,
  useAlertRuleDetail,
  useAlertTreeGrid,
} from '@/alert/components/alertGrid/alertGrid.uses';
import { computed, ref, watch, watchEffect } from 'vue';
import { PlatformType } from '@/types/common.types';
import { getCurrentByServiceAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { GLOBAL_TIME_LIVE_FORMAT } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.define';
import { useEvGridFilterSearch } from '@/common/components/molecules/filterSearch/filterSearch.uses';
import { getFilterItemsByGridColumns } from '@/common/components/molecules/filterSearch/filterSearch.utils';

export type PlatformAlertInfo = {
  targetId: string;
  platform: PlatformType;
};

export interface Props {
  info: PlatformAlertInfo;
  isShow?: boolean;
}

export const VIEW_TOGGLE_ACTIVE_COLOR = '#10b981';
export const VIEW_TOGGLE_INACTIVE_COLOR = '#f97316';

export const VIEW_TOGGLE = {
  LIST_VIEW_PANEL: 'LIST_VIEW_PANEL',
  CHART_VIEW_PANEL: 'CHART_VIEW_PANEL',
} as const;

const usePlatformAlert = () => {
  const { setIsDetailLoading } = getSlideDetailStore();
  const { callApi } = useRtmApi();
  const { alertColumns, makeAlertTreeRows } = useAlertTreeGrid({ columnOption: 'searchable' });

  const alertRows = ref<AlertTreeRow[]>([]);

  const fetchAlert = async ({
    targetId,
    platform,
  }: {
    targetId: string;
    platform: PlatformType;
  }) => {
    if (!targetId || !platform) {
      setIsDetailLoading(false);
      return;
    }

    try {
      const { data = [] } = await callApi({
        fn: getCurrentByServiceAlertCommonControllerAxios,
        params: {
          targetIds: [targetId],
          targetKind: platform,
        },
      });

      alertRows.value = makeAlertTreeRows({ data });
    } finally {
      setIsDetailLoading(false);
    }
  };

  return {
    alertColumns,
    alertRows,
    fetchAlert,
  };
};

const setup = (props: Props) => {
  const { t } = useInternational();

  const { alertColumns, alertRows, fetchAlert } = usePlatformAlert();

  const viewToggle = ref<keyof typeof VIEW_TOGGLE>(VIEW_TOGGLE.LIST_VIEW_PANEL);

  const filterItems = getFilterItemsByGridColumns(alertColumns);
  const { filterSearchResultMV, filterTreeGridByFilterSearch } = useEvGridFilterSearch({
    columns: computed(() => alertColumns),
    filterItems,
  });

  const filteredAlertRows = ref<AlertTreeRow[]>([]);
  watchEffect(() => {
    filteredAlertRows.value = filterTreeGridByFilterSearch(
      alertRows.value,
      filterSearchResultMV.value,
    );
  });

  const { setIsDetailLoading } = getSlideDetailStore();

  const timePeriodInfo = ref<TimePeriodInfo>({
    ...getInitPeriodInfo({ timeFormat: GLOBAL_TIME_LIVE_FORMAT }),
    isPaused: !props.isShow,
  });

  const { showAlertRuleDetail } = useAlertRuleDetail();
  const onClickCell = async ({ field, row }: { field: string; row: AlertTreeRow }) => {
    if (field === 'ruleName') {
      showAlertRuleDetail({ info: row });
    }
  };

  const onRefresh = (timePeriod: TimePeriodInfo) => {
    timePeriodInfo.value = timePeriod;
    if (props.isShow && !timePeriod.isPaused) {
      fetchAlert({
        targetId: props.info.targetId,
        platform: props.info.platform,
      });
    }
  };

  watch(
    () => props.isShow,
    (isShow) => {
      if (isShow) {
        setIsDetailLoading(true);
        timePeriodInfo.value.isPaused = false;
        return;
      }
      timePeriodInfo.value.isPaused = true;
    },
  );

  return {
    alertColumns,
    filteredAlertRows,
    timePeriodInfo,
    viewToggle,
    filterSearchResultMV,
    filterItems,
    onRefresh,
    onClickCell,
    t,
  };
};

export { setup };
